import React from 'react';
import { BasicNavDropdown } from './NavComponents';

export function AboutDropdown(props) {
  const menuItems = ['Overview', 'Leagues of Chapters', 'List of Chapters'];
  const linkLocations = [
    '/aboutus',
    '/aboutus/liendoan',
    '/aboutus/listofdoan',
  ];
  const toggleLabel = 'About Us';

  return (
    <BasicNavDropdown
      menuItems={menuItems}
      linkLocations={linkLocations}
      toggleLabel={toggleLabel}
      hovercontrol={props.hovercontrol}
    />
  );
}
