import React from 'react';
import { DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link } from 'gatsby';
import HoverControlledDropdown from '../../HoverControlledDropdown/HoverControlledDropdown';
import ToggleableDropdown from '../../ToggleableDropdown/ToggleableDropdown';

/*  Function generates dropdown for the navbar. Note that the function expects menuItems and linkLocations to be the same size
    @param props.menuItems List of strings that is to be displayed in the menu
    @param props.linkLocations List of strings that specifies locations of our links if the user clicks on the particular menuItem
    @param props.toggleLabel Text of the collaspsed dropdown
    @return HoverControlledDropdown with populated menu and toggle
*/
export function BasicNavDropdown(props) {
  const menu = (
    // Outputs dropdown menu with links by mapping list of strings
    <DropdownMenu>
      {props.menuItems.map((elem, index) => {
        // Returns <Link> for internal links and <a> for external links (if http is in the link)
        return props.linkLocations[index].includes('http') ? (
          <a
            key={elem}
            href={props.linkLocations[index]}
            target="_blank"
            rel="noopener noreferrer"
            className="dropdown-item"
          >
            {elem} &nbsp;
            <i className="fa fa-external-link" />
          </a>
        ) : (
          <OurNavLink key={elem} text={elem} to={props.linkLocations[index]} />
        );
      })}
    </DropdownMenu>
  );

  const toggle = (
    <DropdownToggle caret className="nav-link" tag="button">
      {props.toggleLabel}
    </DropdownToggle>
  );

  if (props.hovercontrol === true)
    return (
      <div>
        <HoverControlledDropdown nav inNavbar>
          {toggle}
          {menu}
        </HoverControlledDropdown>
      </div>
    );

  return (
    <div>
      <ToggleableDropdown nav inNavbar>
        {toggle}
        {menu}
      </ToggleableDropdown>
    </div>
  );
}

function OurNavLink(props) {
  return (
    <Link
      activeClassName="nav-link-active"
      className="dropdown-item"
      to={props.to}
    >
      {props.text}
    </Link>
  );
}
