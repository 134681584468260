import React from 'react';
import { BasicNavDropdown } from './NavComponents';

export function ResourcesDropdown(props) {
  const menuItems = [
    'Resources Index',
    'Bylaws',
    'Ceremony Manual',
    'Training Regulations',
    'Training Resources',
    'Official Songs',
    'Prayers',
    'Specialized Skills',
    'Curricula',
    'Weekly Gospel Lessons',
    'ASAP',
    'Forms',
    'Education Center',
    'Webinars',
    'Retreats',
  ];
  const linkLocations = [
    '/resources',
    '/resources/bylaws',
    '/resources/ceremonymanual',
    '/resources/trainingregulations',
    '/resources/trainingresources',
    '/resources/officialsongs',
    '/resources/prayers',
    '/resources/specializedskills',
    '/resources/curricula',
    '/resources/weeklygospel',
    '/asap',
    '/resources/forms',
    'https://educenter.veym.net',
    '/resources/webinars',
    '/resources/retreats',
  ];
  const toggleLabel = 'Resources';

  return (
    <BasicNavDropdown
      menuItems={menuItems}
      linkLocations={linkLocations}
      toggleLabel={toggleLabel}
      hovercontrol={props.hovercontrol}
    />
  );
}
