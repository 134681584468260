import React from 'react';
import { NavItem, NavLink } from 'reactstrap';

export function StoreNavItem(props) {
  return (
    <NavItem
      tag={NavLink}
      href="https://store.veym.net"
      target="_blank"
      rel="noopener noreferrer"
    >
      Store
      {/* <MediaQuery query="(max-width: 990px)">
          &nbsp;&nbsp;
          <i className="fa fa-external-link" />
        </MediaQuery> */}
    </NavItem>
  );
}
