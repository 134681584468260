import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import SearchModal from './SearchModal';

const SearchHOC = props => (
  <StaticQuery
    query={graphql`
      query SearchHOCIndexQuery {
        siteSearchIndex {
          index
        }
      }
    `}
    render={data => <SearchModal index={data.siteSearchIndex.index} />}
  />
);

export default SearchHOC;
