import React from 'react';
import { BasicNavDropdown } from './NavComponents';

export function StewardshipDropdown(props) {
  const menuItems = [
    'Membership Info',
    'Members Portal',
    'Donate',
    'VEYM Email',
  ];
  const linkLocations = [
    '/stewardship/membership',
    'https://members.veym.net',
    '/stewardship/donate',
    'https://email.veym.net',
  ];
  const toggleLabel = 'Stewardship';

  return (
    <BasicNavDropdown
      menuItems={menuItems}
      linkLocations={linkLocations}
      toggleLabel={toggleLabel}
      hovercontrol={props.hovercontrol}
    />
  );
}
