import React from 'react';
import { BasicNavDropdown } from './NavComponents';

export function EventsDropdown(props) {
  const menuItems = ['Training Camps', 'Other Events', 'Joshua Congress 2022'];
  const linkLocations = ['/events', '/other-events', '/joshua-congress-2022'];
  const toggleLabel = 'Events';

  return (
    <BasicNavDropdown
      menuItems={menuItems}
      linkLocations={linkLocations}
      toggleLabel={toggleLabel}
      hovercontrol={props.hovercontrol}
    />
  );
}
