import React from 'react';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';
import { Link } from 'gatsby';
import MediaQuery from 'react-responsive';
import tnttlogo from '../../img/tnttlogo-1.png';
import SearchHOC from '../SearchComponent/SearchHOC';
import styles from './Navbar.module.css';

import { HomeNavItem } from './NavComponents/Home';
import { AboutDropdown } from './NavComponents/AboutUs';
import { NewsNavItem } from './NavComponents/News';
import { EventsDropdown } from './NavComponents/Events';
import { ResourcesDropdown } from './NavComponents/Resources';
import { StoreNavItem } from './NavComponents/Store';
import { ContactNavItem } from './NavComponents/Contact';
import { StewardshipDropdown } from './NavComponents/Stewardship';

class NavbarComponent extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  toggle() {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  }

  render() {
    const { isOpen } = this.state;

    return (
      <header className={styles.navbar}>
        <Navbar color="dark" dark expand="lg" fixed="top">
          <Link className="navbar-brand" to="/">
            <div className="navbar-brand-name">
              <div className={styles.tnttName}>
                <img
                  src={tnttlogo}
                  className={styles.veymLogo}
                  alt="veym-logo"
                />
                <div className={styles.brandTextFull}>
                  <div className={styles.brandNameEng}>
                    VIETNAMESE EUCHARISTIC YOUTH MOVEMENT IN THE U.S.A.
                  </div>
                  <div className={styles.brandNameVn}>
                    PHONG TRÀO THIẾU NHI THÁNH THỂ VIỆT NAM TẠI HOA KỲ
                  </div>
                </div>
                <div className={styles.brandTextShort}>
                  <div className={styles.brandNameEng}>VEYM</div>
                  <div className={styles.brandNameVn}>TNTT</div>
                </div>
              </div>
            </div>
          </Link>
          <NavbarToggler onClick={this.toggle} aria-label="toggleNavbar" />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <MediaQuery query="(min-width: 992px)">
                <HomeNavItem />
                <AboutDropdown hovercontrol={true} />
                <NewsNavItem />
                <EventsDropdown hovercontrol={true} />
                <ResourcesDropdown hovercontrol={true} />
                <StoreNavItem />
                <ContactNavItem />
                <StewardshipDropdown hovercontrol={true} />
              </MediaQuery>
              <MediaQuery query="(max-width: 991px)">
                <HomeNavItem />
                <AboutDropdown hovercontrol={false} />
                <NewsNavItem />
                <EventsDropdown hovercontrol={false} />
                <ResourcesDropdown hovercontrol={false} />
                <StoreNavItem />
                <ContactNavItem />
                <StewardshipDropdown hovercontrol={false} />
              </MediaQuery>

              <NavItem>
                <SearchHOC />
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
export default NavbarComponent;
