import React from 'react';
import { NavItem } from 'reactstrap';
import { Link } from 'gatsby';

export function HomeNavItem(props) {
  return (
    <NavItem
      tag={Link}
      activeClassName="nav-link-active"
      className="nav-link"
      to="/"
    >
      Home
    </NavItem>
  );
}
