import React, { useState } from 'react';
import { Dropdown } from 'reactstrap';
import styles from './ToggleableDropdown.module.css';

export default function ToggleableDropdown(props) {
  const [isOpenByClick, updateIsOpenByClick] = useState(false);

  return (
    <Dropdown
      {...props}
      className={styles.heldOpen}
      isOpen={isOpenByClick} // Open on hover or click
      toggle={() => {
        // When toggling, click to keep open or click to close
        updateIsOpenByClick(!isOpenByClick);
      }}
    />
  );
}
