import React from 'react';
// import 'font-awesome/css/font-awesome.min.css';
import { Container, Row, Col } from 'reactstrap';
import MediaQuery from 'react-responsive';
import {
  FaGooglePlay,
  FaFacebookSquare,
  FaYoutube,
  FaAppStoreIos,
  FaInstagram,
  FaTwitterSquare,
} from 'react-icons/fa';
import styles from './footer.module.css';
import tnttFooterLogoTransparent from '../../img/tnttLogoTransparent.png';
import { Link } from 'gatsby';

export default class Footer extends React.Component {
  render() {
    return (
      <div>
        <section className={styles.colorbar} />
        <footer className="footer">
          <Container>
            <Row>
              <Col md={7} sm={12}>
                <div className={styles.footerName}>
                  <img
                    src={tnttFooterLogoTransparent}
                    className={styles.footerVeymLogo}
                    alt="veym-logo-footer"
                  />
                  <div>
                    <MediaQuery query="(min-width: 426px)">
                      <b>The </b>
                    </MediaQuery>
                    <b>Vietnamese Eucharistic Youth Movement in the U.S.A.</b>
                    <br />
                    <b>Phong Trào Thiếu Nhi Thánh Thể Việt Nam tại Hoa Kỳ</b>
                    <br />
                    1811 E Center St, Anaheim, CA 92805.
                    <MediaQuery query="(max-width: 768px)">
                      <br />
                    </MediaQuery>
                    &nbsp;Phone: 714-603-7586
                    <br />
                    Email:{' '}
                    <a
                      className={styles.emailLink}
                      href="mailto:headquarters@veym.net"
                    >
                      headquarters@veym.net
                    </a>
                    <br />
                    {`Copyright © 1975 - ${new Date().getFullYear()}. All Rights
                  Reserved`}
                  </div>
                </div>
              </Col>
              <Col md={1} sm={12}>
                <div>&nbsp;</div>
              </Col>
              <Col md={4} sm={12}>
                <div>
                  <div className={styles.iconsContainer}>
                    <a
                      href="https://www.facebook.com/veym.usa.tntt/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="facebook"
                    >
                      <FaFacebookSquare
                        className={`${styles.fbIcon} ${styles.socialIcon}`}
                        size="1.5em"
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCj_Oh1W16LdOf0h6m9kUd1Q"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="youtube"
                    >
                      <FaYoutube
                        className={`${styles.ytIcon} ${styles.socialIcon}`}
                        size="1.5em"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/veym.usa.tntt/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="instagram"
                    >
                      <FaInstagram
                        className={`${styles.igIcon} ${styles.socialIcon}`}
                        size="1.5em"
                      />
                    </a>
                    <a
                      href="https://twitter.com/VeymUsaTntt"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="twitter"
                    >
                      <FaTwitterSquare
                        className={`${styles.twIcon} ${styles.socialIcon}`}
                        size="1.5em"
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=org.tntt.veym"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="google play"
                    >
                      <FaGooglePlay
                        className={`${styles.gpIcon} ${styles.socialIcon}`}
                        size="1.5em"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/veym/id1394272435"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="app store"
                    >
                      <FaAppStoreIos
                        className={`${styles.asIcon} ${styles.socialIcon}`}
                        size="1.5em"
                      />
                    </a>
                  </div>
                  <div className={styles.privacy}>
                    <Link className={styles.link} to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    );
  }
}
