import React, { useState } from 'react';
import { Dropdown } from 'reactstrap';
import styles from './HoverControlledDropdown.module.css';

export default function HoverControlledDropdown(props) {
  const [isOpenByClick, updateIsOpenByClick] = useState(false);
  const [isOpenByHover, updateIsOpenByHover] = useState(false);
  const [timeout, updateTimeout] = useState(0);

  const delay = 50; // Delay for the menu timeout in ms

  const dropdownTimeout = () => {
    return setTimeout(function () {
      updateIsOpenByHover(false);
    }, delay);
  };

  return (
    <Dropdown
      {...props}
      className={styles.heldOpen}
      onMouseOver={() => {
        updateIsOpenByHover(true);
        clearTimeout(timeout);
      }}
      onMouseLeave={() => {
        updateTimeout(dropdownTimeout());
      }}
      isOpen={isOpenByClick || isOpenByHover} // Open on hover or click
      toggle={() => {
        // When toggling, click to keep open or click to close
        updateIsOpenByClick(!isOpenByClick);
        updateIsOpenByHover(!isOpenByClick);
      }}
    />
  );
}
