import React from 'react';
import Helmet from 'react-helmet';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import '../css/homes.css';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', {
    speed: 180,
    offset: 90,
  });
}

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet
      title="VEYM | Vietnamese Eucharistic Youth Movement"
      meta={[
        {
          name: 'description',
          content: `Official Website of the Vietnamese Eucharistic Youth Movement in the USA (Phong Trào Thiếu Nhi Thánh Thể Việt Nam tại Hoa Kỳ), a non-profit organization under the leadership of the Catholic Church. Contains recent TNTT announcements, news, events, resources, and other information.`,
          theme_color: `#252c32`,
        },
      ]}
      htmlAttributes={{ lang: 'en' }}
    />
    <Navbar />

    <main style={{ paddingTop: '80px', minHeight: '95vh' }}>{children}</main>

    <Footer />
  </div>
);

export default TemplateWrapper;
