import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Index } from 'elasticlunr';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  InputGroupAddon,
  InputGroup,
  Badge,
  ListGroup,
  ListGroupItem,
  NavLink,
  UncontrolledPopover,
  PopoverBody,
} from 'reactstrap';
import styles from './Search.module.css';
import MediaQuery from 'react-responsive';
import { AllHtmlEntities } from 'html-entities';

import moment from 'moment';

class SearchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      query: ``,
      results: [],
    };
  }

  toggle = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.index);

  search = evt => {
    const query = evt.target.value;
    this.index = this.getOrCreateIndex();
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    });
  };

  render() {
    // filter out category listings
    const filteredResults = this.state.results.filter(
      page => page.type !== 'category'
    );

    return (
      <div>
        <MediaQuery query="(max-width: 990px)">
          <div className={styles.button}>
            <NavLink color="link" onClick={this.toggle}>
              Search &nbsp;&nbsp;
              <i className="fa fa-search" />
            </NavLink>
          </div>
        </MediaQuery>
        <MediaQuery query="(min-width: 991px)">
          <div className={styles.button}>
            <Button
              color="link"
              id="searchButton"
              onClick={this.toggle}
              aria-label="search"
            >
              <i className="fa fa-search" />
            </Button>
            <UncontrolledPopover
              placement="bottom"
              target="searchButton"
              trigger="hover"
            >
              <PopoverBody>Search &nbsp;</PopoverBody>
            </UncontrolledPopover>
          </div>
        </MediaQuery>

        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggle}
          className={styles.modal}
          autoFocus={false} //eslint-disable-line
          // className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Search</ModalHeader>
          <ModalBody>
            <div>
              <InputGroup>
                <Input
                  type="text"
                  value={this.state.query}
                  onChange={this.search}
                  autoFocus={true} //eslint-disable-line
                />
                <InputGroupAddon addonType="append">
                  <Button
                    onClick={() =>
                      this.setState({ query: '' }, () => {
                        this.search({ target: { value: '' } });
                      })
                    }
                  >
                    Clear
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              <ListGroup>
                {filteredResults.map(page => (
                  <ListGroupItem key={page.id}>
                    {page.path !== 'news' && page.path.includes('news') && (
                      <Badge>News</Badge>
                    )}
                    {page.type === 'file' && (
                      <a
                        href={page.fileLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Badge color="info">{page.fileType}</Badge>
                        &nbsp;
                      </a>
                    )}
                    {page.path !== 'news' && page.path.includes('news') ? (
                      <Link
                        className={styles.searchLink}
                        // to={`/news${page.newSlug}`}
                        // to={`/news${page.path}`}
                        to={page.path}
                      >
                        {new AllHtmlEntities().decode(page.title)}
                      </Link>
                    ) : (
                      <Link className={styles.searchLink} to={page.path}>
                        {new AllHtmlEntities().decode(page.title)}
                      </Link>
                    )}

                    {page.path !== 'news' && page.path.includes('news') && (
                      <small>
                        &nbsp; - {moment(page.date).format('MM/DD/YY')}
                      </small>
                    )}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default SearchModal;
